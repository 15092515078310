// See other presets to try at https://theme-ui.com/packages/presets
import { funk } from '@theme-ui/presets'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  initialColorMode: 'dark',
  ...funk,
  colors: {
    text: '#000',
    lightText: '#999',
    background: '#fff',
    primary: '#6e67ff',
    secondary: '#212121',
    ligther: '#aaa',
    ultraLigther: '#e0e0e0',
    muted: '#e0e0e0',
    link: '#444',
    linkHover: '#000',
    modes: {
      dark: {
        lightText: '#e0e0e0',
        text: '#fff',
        background: '#000',
        secondary: '#fff',
        ligther: '#999',
        ultraLigther: '#333',
        muted: '#555',
        link: '#e0e0e0',
        linkHover: '#fff'
      }
    }
  },
  styles: {
    ...funk.styles,
    a: {
      color: 'link',
      textDecoration: 'none',
      borderColor: 'link',
      borderWidth: '1px',
      borderBottomStyle: 'solid',
      ':hover': {
        color: 'linkHover',
        border: 'none'
      }
    },
    h2: {
      ...funk.styles.h2,
      fontSize: '1.3rem'
    },
    spinner: {
      color: 'text'
    }
  },
  fonts: {
    body: 'Montserrat, sans-serif',
    heading: '"Bungee", sans-serif',
    monospace: 'Menlo, monospace'
  },
  badges: {
    outline: {
      color: 'ligther',
      bg: 'transparent',
      boxShadow: 'inset 0 0 0 1px',
      fontWeight: 400,
      textTransform: 'uppercase',
      fontSize: '0.6rem'
    }
  }
}
