// custom typefaces
// import 'typeface-montserrat'
// import 'typeface-bungee'

// normalize CSS across browsers
// import './src/normalize.css'
// custom CSS styles
import './src/style.css'

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  window.history.scrollRestoration = 'manual'
  const currentPosition = getSavedScrollPosition(location)
  const top = currentPosition?.[1] || 0

  window.setTimeout(() => {
    window.scrollTo({ top, behavior: 'smooth' })
  }, 700)
}
